import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { UserEditComponent } from './user/edit/edit.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { VerifyComponent } from './verify/verify.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'maintenance',
  //   pathMatch: 'full'
  // },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'browser-not-supported',
    component: BrowserNotSupportedComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'verify',
    component: VerifyComponent
  },
  {
    path: 'user/:uid',
    component: UserEditComponent
  },
  {
    path: 'manager',
    loadChildren: './manager/manager.module#ManagerModule'
  },
  {
    path: 'staff',
    loadChildren: './staff/staff.module#StaffModule'
  },
  // {
  //   path: '**',
  //   redirectTo: 'maintenance',
  //   pathMatch: 'full'
  // }
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
