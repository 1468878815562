import { Component } from '@angular/core';
// import { FirebaseApp } from '@angular/fire';
// public app: FirebaseApp
// import { AngularFirestore } from '@angular/fire/firestore';
// import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nisouken';

  constructor(private router: Router) {
    // URL tracking
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

}
