import { Injectable } from '@angular/core';
import { Jsonp, URLSearchParams, Http, Headers, RequestOptions, Request, Response } from '@angular/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
// import { User } from '../common/interface';
import saveAs from 'file-saver';
import { OrderByDirection } from '@firebase/firestore-types';
import * as XLSX from 'xlsx';
import * as  firebase from 'firebase';
// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  collection: AngularFirestoreCollection<any>;
  document: AngularFirestoreDocument<any>;
  items: Observable<any>;
  item: Observable<any>;

  collableData$: Observable<any>;
  demoCollableData$: Observable<any>;

  constructor(
    private http: Http,
    private router: Router,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
  ) {
    // afs.firestore.settings({ timestampsInSnapshots: true });
  }

  public get_serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  public get_createdId() {
    return this.afs.createId();
  }

  public set(path: string, obj: object): Promise<any> {
    return this.afs.collection<any>(path).doc(obj['id']).set(obj);
  }

  public set_id(path: string, id: string, obj: object): Promise<any> {
    return this.afs.collection<any>(path).doc(id).set(obj);
  }

  public update(path: string, id: string, obj: object): Promise<any> {
    return this.afs.collection<any>(path).doc(id).update(obj);
  }

  public delete(path: string, id: string): Promise<any> {
    return this.afs.collection<any>(path).doc(id).delete();
  }

  public get(path: string, id: string): Observable<any> {
    return this.afs.collection(path).doc<any>(id).valueChanges();
  }

  public get_collection(path: string): Observable<any> {
    return this.afs.collection<any>(path).valueChanges();
  }

  public get_collection_where(path: string, key1: string, value1: any): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      // query = query.orderBy(order, direction);
      // if (key2 && value2) { query = query.where(key2, '==', value2); }
      // if (key3 && value3) { query = query.where(key3, '==', value3); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      return query;
    }).valueChanges();
  }

  public get_collection_where2(path: string, key1: string, value1: any, key2: string, value2: any): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      if (key2 && value2) { query = query.where(key2, '==', value2); }
      // if (key3 && value3) { query = query.where(key3, '==', value3); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      // query = query.orderBy(order, direction);
      return query;
    }).valueChanges();
  }



  public get_collection_where3(path: string, key1: string, value1: any, key2: string, value2: any, key3: string, value3: any): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      if (key2 && value2) { query = query.where(key2, '==', value2); }
      if (key3 && value3) { query = query.where(key3, '==', value3); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      // query = query.orderBy(order, direction);
      return query;
    }).valueChanges();
  }


  public get_collection_where4(path: string, key1: string, value1: any, key2: string, value2: any, key3: string, value3: any, key4: string, value4: any): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      if (key2 && value2) { query = query.where(key2, '==', value2); }
      if (key3 && value3) { query = query.where(key3, '==', value3); }
      if (key4 && value4) { query = query.where(key4, '==', value4); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      // query = query.orderBy(order, direction);
      return query;
    }).valueChanges();
  }


  public get_collection_orderby(path: string, order: string, direction: OrderByDirection): Observable<any[]> {
    return this.afs.collection(path,
      ref => ref.orderBy(order, direction)
    ).valueChanges();
  }

  public get_collection_where_orderby(path: string, key1: string, value1: any, order: string, direction: OrderByDirection): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      query = query.orderBy(order, direction);
      // if (key2 && value2) { query = query.where(key2, '==', value2); }
      // if (key3 && value3) { query = query.where(key3, '==', value3); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      return query;
    }).valueChanges();
  }

  public get_collection_where2_orderby(path: string, key1: string, value1: any, key2: string, value2: any, order: string, direction: OrderByDirection): Observable<any[]> {
    // const childref = this.afs.collection<any>(parentPath).doc(parentId);
    return this.afs.collection<any>(path, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (key1 && value1) { query = query.where(key1, '==', value1); }
      if (key2 && value2) { query = query.where(key2, '==', value2); }
      // if (key3 && value3) { query = query.where(key3, '==', value3); }
      // // 要検討 下記の記述はインデックス作成を要求されるので使用しない
      // 単独で検索させる
      // if (resource && week) { query = query.where(`selected_resources.${resource.id + week.id}`, '==', true) };
      //   query = query.orderBy(order, 'asc');
      query = query.orderBy(order, direction);
      return query;
    }).valueChanges();
  }


  // 20180130追加
  public tableToExcel(table: string, name: string) {
    // reference: http://sheetjs.com/demos/writexlsx.html
    const tbl = document.getElementById(table);
    const wb = XLSX.utils.table_to_book(tbl);

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== wbout.length; ++i) {
      view[i] = wbout.charCodeAt(i) & 0xFF;
    }

    const date = new Date();
    const year = date.getFullYear(); // 2012
    const month = date.getMonth() + 1;
    const day = date.getDate(); // 6 (6日)
    const filename = year.toString() + '年' + month.toString() + '月' + day.toString() + '日現在' + name + '.xlsx';

    saveAs(new Blob([buf], { type: 'application/octet-stream' }), filename);
  }

  // 20180130追加
  public jsonToExcel(header: Array<any>, data: Array<any>, name: string) {

    // data = [ { "agentNo":"324234", "subName":"30, Jul 2013 09:24 AM" }, { "agentNo":"444443", "subName":"30, Jul 2013 09:24 AM" } ];

    /* create workbook & set props*/
    const wb = { SheetNames: [], Sheets: {} };
    //  wb.Props = {
    //     Title: "Stats from app",
    //     Author: "John Doe"
    //  };

    /*create sheet data & add to workbook*/
    const ws = XLSX.utils.json_to_sheet(data, { header: header });
    const ws_name = 'DataSheet 1';
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* create file 'in memory' */
    // const wbout = new Buffer(XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' }));

    // type AOA = any[][];
    // data = [[1, 2], [3, 4]];
    // reference: http://sheetjs.com/demos/writexlsx.html
    // const tbl = document.getElementById(table);
    //  const wb = XLSX.utils.table_to_book(tbl);
    //  const wbout = XLSX.write(ws, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    // const buf = new ArrayBuffer(wbout.length);
    // const view = new Uint8Array(buf);
    // for (let i = 0; i !== wbout.length; ++i) {
    //   view[i] = wbout.charCodeAt(i) & 0xFF;
    // }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== wbout.length; ++i) {
      view[i] = wbout.charCodeAt(i) & 0xFF;
    }

    const date = new Date();
    const year = date.getFullYear(); // 2012
    const month = date.getMonth() + 1;
    const day = date.getDate(); // 6 (6日)
    const filename = year.toString() + '年' + month.toString() + '月' + day.toString() + '日現在' + name + '.xlsx';

    saveAs(new Blob([buf], { type: 'application/octet-stream' }), filename);
  }


  // this.places = this.places.sort(this.compareValues('kana'));
  // function for dynamic sorting
  public compareValues(parentkey: string, childkey: string, order = 'asc') {
    return (a: any, b: any) => {
      if (!a.hasOwnProperty(parentkey) || !b.hasOwnProperty(parentkey)) {
        // property doesn't exist on either object
        return 0;
      }
      const varA = (typeof a[parentkey][childkey] === 'string') ? a[parentkey][childkey].toUpperCase() : a[parentkey][childkey];
      const varB = (typeof b[parentkey][childkey] === 'string') ? b[parentkey][childkey].toUpperCase() : b[parentkey][childkey];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  /**
   * Decimal adjustment of a number.
   *
   * @param String  type  The type of adjustment.
   * @param Number  value The number.
   * @param Integer exp   The exponent (the 10 logarithm of the adjustment base).
   * @returns Number The adjusted value.
   */
  public decimalAdjust(type: string, value: any, exp: number) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (value === null || isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    // console.log(value);
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }


  // 全角->半角変換
  public zen2han(str: string) {
    str = str.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    return str;
  }


  public trimhyphen(str: string) {
    str = str.trim().replace(/[^0-9^\.]/g, '');
    return str;
  }


  // slack通知
  public sendSlack(payload: any) {

    const url = 'https://hooks.slack.com/services/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';

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post(url, payload, { headers: headers })
      .subscribe(res => {
        // console.log(res);
      }, error => {
        // console.log(error);
      });

  }



  // slack通知
  public sendSlackToN(payload: any) {

    const url = 'https://hooks.slack.com/services/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';

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post(url, payload, { headers: headers })
      .subscribe(res => {
        // console.log(res);
      }, error => {
        // console.log(error);
      });

  }

  //////////////  //////////////
  public collableSendSlack(data: any) {
    const callable = this.fns.httpsCallable('sendSlack');
    this.collableData$ = callable(data);
    this.collableData$.subscribe((result) => {
      console.log(result);
      // .then((result) => {
      // Read result of the Cloud Function.
      // const sanitizedMessage = result.text;
      // console.log('sanitizedMessage:', sanitizedMessage);
      // return { result: result };
    }, error => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      // ...
      console.log('error:', 'code:', code, 'message:', message, 'details:', details);
      // return { error: error };
    });
  }


  // 'application/pdf'
  // 'image/jpeg'
  public upload_storage(file: any, dbPath: string, Id: string, fileName: string, contentType: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const storage = firebase.storage();
      const storageRef = storage.ref();
      // place / placeid / photo1...(ファイル名はここでリネームされて登録)
      const filePath = `/${dbPath}/${Id}/${fileName}`;
      const fileRef = storageRef.child(filePath);

      const task = fileRef.put(file, { contentType: contentType })
        .then(res => {
          console.log(res);
          storageRef.child(filePath).getDownloadURL().then((url: string) => {
            console.log(url);
            return resolve(url);
          }).catch((error) => {
            return reject(error);
          });
        }).catch(error => {
          return reject(error);
        });
    });
  }


  public delete_storage(dbPath: string, Id: string, fileName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const storage = firebase.storage();
      const storageRef = storage.ref();
      storageRef.child(`/${dbPath}/${Id}/${fileName}`)
        .delete()
        .then(() => {
          resolve();
        }).catch(error => {
          return reject(error);
        });
    });
  }

}

