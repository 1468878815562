import { Group, Block, Member, BlockGoals, GroupGoals, GroupReports, User } from '../interfaces/interface';

export namespace CoreConst {

    export const VERSION = {
      version: '17'
    };

  // 目標設定で使用する期間(12/1-12/31)
  export const ABLE_ACCESS_GROUP_GOAL_PERIOD = {
    START_MONTH: 11, // 12月
    START_DAY: 1,
    END_MONTH: 11, // 12月
    END_DAY: 31,
  };

  // 目標設定で使用する期間(1/1-1/10)
  export const ABLE_ACCESS_MANAGER_GOAL_PERIOD = {
    START_MONTH: 0, // 1月
    START_DAY: 1,
    END_MONTH: 0, // 1月
    END_DAY: 10,
  };
  
  // 目標設定で使用する期間(1/11-1/20)
  export const ABLE_ACCESS_GROUP_REIKAI_RIJIKAI_REPORT_PERIOD = {
    START_MONTH: 0, // 1月
    START_DAY: 11,
    END_MONTH: 0, // 1月
    END_DAY: 20,
  };

  export const NOT_ACCESS_REPORT_PERIOD = {
    START_MONTH: 1,
    START_DAY: 1,
    END_MONTH: 1,
    END_DAY: 20,
  };

    // 報告日期限設定　毎月何日までに報告するのか
    export const DEADLINE = {
        group: {
            report: 3,
        },
        block: {
            report: 5,
        },
        reply:{
            report: 10,
        }
    };

    export const INIT_CONTACTUS = {
        id: '',
        date: '',
        user: {
            uid: '',
            displayName: '',
            email: '',
            group: {
                block: {
                    no: 0,
                    name: ''
                },
                id: '',
                no: 0,
                name: '',
            },
            role: {
                name: '',
                value: ''
            },
        },
        message: '',
        url: '',
        version: ''
    };

    export const INIT_ROLES = [
        { id: 0, name: '日創研', value: 'nisouken' }, // true
        { id: 1, name: '本部役員', value: 'honbu' }, // only group
        { id: 2, name: '組織活性化委員長', value: 'manager' }, //  true
        { id: 3, name: '組織活性化副委員長', value: 'manager' }, // true
        { id: 4, name: 'ブロック長', value: 'block' }, // block
        { id: 5, name: '副ブロック長', value: 'block' }, // block
        { id: 6, name: '単会会長', value: 'group' }, // only group
        { id: 7, name: '単会事務局長', value: 'group' }, // only group
        { id: 8, name: '単会会員', value: 'readonly' }, // only group
        { id: 9, name: '本部事務局', value: 'office' }, // hamamoto,shima,true
        { id: 10, name: '総務委員会', value: 'soumu', }, // true
    ];


    export const INIT_USER: User = {
        displayName: '',
        email: '',
        group: {
            block: {
                deleted: false,
                id: '',
                name: '',
                no: 0,
                order: 0,
            },
            id: '',
            name: '',
            no: 0,
            deleted: false,
        },
        role: {
            id: 0,
            name: '',
            value: '',
        },
        uid: '',
        disabled: false,
    };

    export const INIT_GROUP = {
        block: {
            id: '',
            name: '',
            no: 0,
            order: 0,
        },
        deleted: false,
        id: '',
        name: '',
        no: 0
    };

    export const INIT_BLOCK = {
        id: '',
        name: '',
        no: 0,
        order: 0,
    };

    export const JOIN_TYPRES = ['一般入会','トライアル入会'];

    export const INIT_MEMBER: Member = {
        id: '',
        name: '',
        company: '',
        email: '',
        telephone: '',
        category: '',
        type: '',
        joinType: '一般入会',
        reason: '',
        year: 0,
        month: 0,
        group: {
            block: {
                id: '',
                order: 0,
                no: 0,
                name: '',
                deleted: false
            },
            id: '',
            name: '',
            no: 0,
            deleted: false,
        },
        groupid: '',
        reportsid: '',
        timestamp: 0
    };

    export const LEAVE_REASON = [
        '業務多忙',
        '業績悪化',
        '会社整理・廃業',
        '会の魅力低下',
        '意欲低下',
        '体調不良',
        '移籍',
        '会社転職・転勤',
        '会社移転',
        '高齢・引退・継承',
        '価値観の相違',
        '他団体多忙',
        'その他',
    ];

    export const INIT_BLOCK_GOALS: BlockGoals = {
        block: {
            id: '',
            order: 0,
            no: 0,
            name: '',
        },
        date1: '',
        date2: '',
        date3: '',
        date4: '',
        date5: '',
        date6: '',
        date7: '',
        date8: '',
        date9: '',
        date10: '',
        date11: '',
        date12: '',
        problem1: '',
        problem2: '',
        problem3: '',
        solution1: '',
        solution2: '',
        solution3: '',
        year: 0,
        id: '',
        timestamp: 0,
        createdBy: {
            uid: '',
            displayName: ''
        },
        createdAt: '',
        updatedBy: {
            uid: '',
            displayName: ''
        },
        updatedAt: '',
    };

    export const INIT_GROUP_GOALS: GroupGoals = {
        member: {
            // start: 0,
            goal: 0,
            joinfee: 0,
            restfee: 0,
            black_rate: 0,
            month1: {
                start: 0,
                end: 0
            },
            month2: {
                start: 0,
                end: 0
            },
            month3: {
                start: 0,
                end: 0
            },
            month4: {
                start: 0,
                end: 0
            },
            month5: {
                start: 0,
                end: 0
            },
            month6: {
                start: 0,
                end: 0
            },
            month7: {
                start: 0,
                end: 0
            },
            month8: {
                start: 0,
                end: 0
            },
            month9: {
                start: 0,
                end: 0
            },
            month10: {
                start: 0,
                end: 0
            },
            month11: {
                start: 0,
                end: 0
            },
            month12: {
                start: 0,
                end: 0
            }
        },
        rijikai: {
            total: 0,
            attendance_rate: 0
        },
        soukai: {
            attendance_rate: 0
        },
        books: {
            tokumoku_rate: 0,
            syanaibenkyoukai_rate: 0
        },
        zenkoku: {
            attendance: 0,
            presenter: 0,
            adviser: 0,
        },
        seminar: {
            tokubetsu: 0
        },
        newer: {
            orientation: 0
        },
        rekidai: {
            meeting: 0,
        },
        reikai: {
            attendance_rate: 0,
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            month5: '',
            month6: '',
            month7: '',
            month8: '',
            month9: '',
            month10: '',
            month11: '',
            month12: '',
        },
        group: {
            block: {
                no: 0,
                name: '',
            },
            id: '',
            no: 0,
            name: '',
            // createdBy: {
            //     uid: '',
            //     displayName: ''
            // },
            // createdAt: '',
            // updatedBy: {
            //     uid: '',
            //     displayName: ''
            // },
            // updatedAt: '',
        },
        year: 0,
        groupid: '',
        id: '',
        timestamp: 0,
        createdBy: {
            uid: '',
            displayName: ''
        },
        createdAt: '',
        updatedBy: {
            uid: '',
            displayName: ''
        },
        updatedAt: '',
    };


    // export const INIT_BLOCK_OBJ: Block = {
    //     no: 0,
    //     name: '',
    //     comment1: '',
    //     comment2: '',
    //     comment3: '',
    //     timestamp: 0,
    //     createdBy: {
    //         uid: '',
    //         displayName: '',
    //     },
    //     createdAt: '',
    //     updatedBy: {
    //         uid: '',
    //         displayName: '',
    //     },
    //     updatedAt: '',
    // };


    // export const INIT_GROUP_OBJ: Group = {
    //     block: INIT_BLOCK_OBJ,
    //     id: '',
    //     name: '',
    //     no: 0,
    //     comment1: '',
    //     comment2: '',
    //     comment3: '',
    //     timestamp: 0,
    //     createdBy: {
    //         uid: '',
    //         displayName: '',
    //     },
    //     createdAt: '',
    //     updatedBy: {
    //         uid: '',
    //         displayName: '',
    //     },
    //     updatedAt: '',
    // };


    export const INIT_GROUP_REPORTS: GroupReports = {
        member: {
            goal: 0,
            start: 0,
            join: 0,
            leave: 0,
            subtotal: 0,
            subtotal_rate: 0, // 達成率
            rest: 0,
            total: 0,
            nensyo: 0,
            junzo: 0
        },
        seminar: {
            sa: 0,
            sc: 0,
            psv: 0,
            tt: 0,
            honbu: 0,
            keikakusyo: 0,
            sa_rate: 0,
            sc_rate: 0,
            psv_rate: 0,
            tt_rate: 0,
            honbu_rate: 0,
            keikakusyo_rate: 0
        },
        books: {
            tokumoku: 0,
            tokumoku_rate: 0,
            syanaibenkyoukai: 0,
            syanaibenkyoukai_rate: 0,
        },
        rijikai: {
            date: '',
            attendance: 0,
            attendance_rate: 0,
            total: 0
        },
        reikai: {
            date: '',
            attendance: 0,
            attendance_rate: 0,
            observe: 0,
            total: 0,
            summary: ''
        },
        reikai_downloadURL: '',
        soukai: {
            date: '',
            attendance: 0,
            attendance_rate: 0,
        },
        trial:{
            comment: '',
            current_number_of_trial_members: 0,
            total_number_of_trial_members: 0,
            number_of_members_after_trial: 0,
            membership_rate_after_trial: 0,
            updatedBy: {
                uid: '',
                displayName: ''
            },
            updatedAt: '',
        },
        group: {
            comment1: '',
            comment2: '',
            comment3: '',
            comment4: '', // deprecated 2024/01 trial新設に伴い廃止
            block: {
                no: 0,
                name: ''
            },
            id: '',
            no: 0,
            name: '',
            createdBy: {
                uid: '',
                displayName: ''
            },
            createdAt: '',
            updatedBy: {
                uid: '',
                displayName: ''
            },
            updatedAt: '',
            completedAt: '',
            completedBy: {
                uid: '',
                displayName: ''
            }
        },
        block: {
            comment1: '',
            createdBy: {
                uid: '',
                displayName: ''
            },
            createdAt: '',
            updatedBy: {
                uid: '',
                displayName: ''
            },
            updatedAt: '',
            completedAt: '',
            completedBy: {
                uid: '',
                displayName: ''
            }
        },
        reply: {
            comment:'',
            updatedBy: {
                uid: '',
                displayName: ''
            },
            updatedAt: '',
        },
        year: 0,
        month: 0,
        groupid: '',
        id: '',
        timestamp: 0,
        createdBy: {
            uid: '',
            displayName: ''
        },
        createdAt: '',
        updatedBy: {
            uid: '',
            displayName: ''
        },
        updatedAt: '',
    };

}
