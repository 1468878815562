import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControlName, FormControl, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit, OnDestroy {

  rootFormGroup: FormGroup;
  account: { email: '' };
  email: string;
  sendFlg = false;

  ngUnsubscribe: Subject<any> = new Subject<any>();
  // actions = UserManagementActions;
  actions: any;
  // The user management actoin to be completed
  mode: string;
  // Just a code Firebase uses to prove that
  // this is a real password reset.
  actionCode: string;

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  actionCodeChecked: boolean;

  errorMessage = [];

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.rootFormGroup = this.fb.group({
      email: [
        '',
        Validators.required
      ],
    });
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(params => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (!params) {
          this.router.navigate(['/login']);
        }

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        // console.log('this.mode,:', this.mode, ' this.actionCode:', this.actionCode);

        switch (this.mode) {
          case 'resetPassword': {
            // Verify the password reset code is valid.
            this.auth.verifyPasswordResetCode(this.actionCode)
              .then(email => {
                this.actionCodeChecked = true;
              })
              .catch(error => {
                this.errorMessage = [];
                this.errorMessage = [...error.errorMessage];
                // console.log(error);
                this.toastr.error('エラーが起こりました。はじめから設定をしてください。');
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                this.router.navigate(['/forget-password']);
              });
          } break;
          case 'recoverEmail': {

          } break;
          case 'verifyEmail': {
            this.auth.applyActionCode(this.actionCode)
              .then(() => {
                this.toastr.success('このメールアドレスは確認がとれました');
                this.router.navigate(['/login']);
              })
              .catch(error => {
                this.errorMessage = [];
                this.errorMessage = [...error.errorMessage];
                // console.log(error);
                this.toastr.error('エラーが起こりました');
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                this.router.navigate(['/forget-password']);
              });

          } break;
          default: {
            // console.log('query parameters are missing');
            this.router.navigate(['/login']);
          }
        }
      });
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  public handleResetPassword() {
    if (this.newPassword !== this.confirmPassword) {
      const message = 'パスワードが一致しません。';
      this.errorMessage = [message];
      return this.toastr.error(message);
    }
    // Save the new password.
    this.auth.confirmPasswordReset(this.actionCode, this.newPassword)
      .then(resp => {
        // Password reset has been confirmed and new password updated.
        this.toastr.success('新しいパスワードを設定しました');
        this.router.navigate(['/login']);
      })
      .catch(error => {
        this.errorMessage = [];
        this.errorMessage = [...error.errorMessage];
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        // this.toastr.error(error);
      });
  }
}
