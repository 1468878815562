import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// import { User, Group } from '../interface';
declare var require: any;
const platform = require('platform');
import { ToastrService } from 'ngx-toastr';
// import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    // private service: CrudService,
    private toastr: ToastrService,
  ) {
    // ログインした時点で、userにobservableで走る
    // Get auth data, then get firestore user document || null
    this.currentUser();
  }

  public checkBrowser() {
    // console.log(platform.name); // Edgeなら中断
    // if (platform.name === 'Microsoft Edge') {
    //   this.router.navigate([`/browser-not-supported`]);
    // } else
    if (platform.name === 'Microsoft IE') {
      this.router.navigate([`/browser-not-supported`]);
    } else if (platform.name === 'IE') {
      this.router.navigate([`/browser-not-supported`]);
    }
  }

  ////////////////////////////////////////////////////////////////
  // user
  public currentUser() {
    // ログインした時点で、userにobservableで走る
    this.user = this.afAuth.authState
      .pipe(
        switchMap(user => {
          // console.log('auth user:', user);
          if (user) {
            // console.log('auth state', user);
            // return user;
            return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
            // return of(user);
          } else {
            // console.log('auth state', null);
            return of(null);
          }
        })
      );
  }


  // async checkUserProfile(uid: string) {
  //   return new Promise((resolve, reject) => {
  //     return this.afs.doc<any>(`users/${uid}`).valueChanges().subscribe((user: any) => {
  //       // 不足情報があれば、ユーザー情報登録画面に繊維
  //       if (typeof user === 'undefined') {
  //         // 目標設定していないので中止、遷移
  //         this.toastr.error('エラー:ユーザー情報が未登録です');
  //         this.router.navigate([`./user/${uid}`]);
  //         return reject(false);
  //       } else if (typeof user.displayName === 'undefined' || user.displayName === '') {
  //         // お名前が設定していないので中止、遷移
  //         this.toastr.error('エラー:お名前が未登録です。');
  //         this.router.navigate([`./user/${uid}`]);
  //         return reject(false);
  //       } else if (typeof user.group === 'undefined' || user.group.id === 'undefined' || user.group.id === '') {
  //         // 目標設定していないので中止、遷移
  //         this.toastr.error('エラー:経営研究会が未登録です。');
  //         this.router.navigate([`./user/${uid}`]);
  //         return reject(false);
  //       } else if (typeof user.role === 'undefined' || user.role.value === 'undefined' || user.role.value === '') {
  //         // 目標設定していないので中止、遷移
  //         this.toastr.error('エラー:報告者区分が未登録です。');
  //         this.router.navigate([`/user/${uid}`]);
  //         return reject(false);
  //       } else {
  //         return resolve(user);
  //       }
  //     });
  //   });
  // }


  // ユーザーのプロフィールを更新する
  public updateProfile(account: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;

      user.updateProfile({
        displayName: account.displayName,
        photoURL: account.photoURL,
      })
        .then(() => {
          // Update successful.
          this.toastr.success('プロフィールを更新しました');
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }


  // ユーザーのメールアドレスを更新する
  public updateEmail(emailAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;

      user.updateEmail(emailAddress)
        .then(() => {
          // Update successful.
          this.toastr.success('メールアドレスを更新しました');
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }


  // ユーザーに確認メールを送信する
  public sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.sendEmailVerification()
        .then(() => {
          // Email sent.
          this.toastr.success('確認メールを送信しました');
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }

  // ユーザーを削除する
  public userDelete(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.delete()
        .then(() => {
          // User deleted.
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }

  // firebase.getAuth().verifyPasswordResetCode


  // ユーザーを再認証する
  // public reauthenticateWithCredential() : Promise<any> {
  //    return new Promise((resolve, reject) => {
  //   const user = firebase.auth().currentUser;
  //   // let credential;// ?????????

  //   // Prompt the user to re-provide their sign-in credentials

  //   // user.reauthenticateWithCredential(credential)
  //   //   .then(() => {
  //   //     // User re-authenticated.
  //   //   })
  //   //   .catch((error) => {
  //   //     // An error happened.
  //   //   });
  //   });
  // }

  // Localize the UI to the selected language as determined by the lang
  // パスワードの再設定メールを送信する
  public sendPasswordResetEmail(emailAddress: string, url?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(emailAddress)
        // auth.sendPasswordResetEmail(emailAddress, { url: url })
        .then(() => {
          // Email sent.
          this.toastr.success('パスワードの再設定メールを送信しました');
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }

  // https://firebase.google.com/docs/auth/custom-email-handler?hl=ja
  // 最初に verifyPasswordResetCode でアクション コードを確認することによって、
  // パスワードのリセット リクエストを処理し、その後ユーザーから新しいパスワードを取得して、
  // それを confirmPasswordReset に渡します。次に例を示します。
  public handleRecoverEmail(actionCode: string) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    this.afAuth.auth.checkActionCode(actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];
        // Revert to the old email.
        return this.applyActionCode(actionCode);
      }).then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        this.sendPasswordResetEmail(restoredEmail)
          .then(() => {
            // Password reset confirmation sent. Ask user to check their email.
          }).catch((error) => {
            // Error encountered while sending password reset code.
          });
      }).catch((error) => {
        // Invalid code.
      });
  }

  // Localize the UI to the selected language as determined by the lang
  // parameter.
  public applyActionCode(actionCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Try to apply the email verification code.
      this.afAuth.auth.applyActionCode(actionCode)
        .then((resp) => {
          // Email address has been verified.
          return resolve(resp);
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        }).catch((error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }


  /**
   * createUserEmailPassword
   * @param email email
   * @param password password
   */
  public createUserWithEmailAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(credentials => {
          resolve(credentials);
        })
        .catch(error => {
          reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }




  /**
   * signInWithEmailAndPassword
   * @param email email
   * @param password password
   */
  public signInWithEmailAndPassword(email, password): Promise<any> {
    return new Promise((resolve, reject) => {
      // window,tabを閉じるとセッションを終了する設定
      // https://firebase.google.com/docs/auth/web/auth-state-persistence?hl=ja
      this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then(credentials => {
              return resolve(credentials);
            }).catch(error => {
              return reject({
                code: error.code,
                message: this.translateErrorMessage(error.code)
              });
            });
        });
    });
  }

  // Verify the password reset code is valid.
  public verifyPasswordResetCode(actionCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.verifyPasswordResetCode(actionCode)
        .then(email => {
          // console.log(email);
          return resolve(email);
        }).catch(error => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }


  public confirmPasswordReset(actionCode: any, newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.confirmPasswordReset(actionCode, newPassword)
        .then(resp => {
          return resolve(resp);
        }).catch(error => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }


  /**
   * googleLogin
   */
  // public googleLogin(role: string, group: Group) {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   return this.oAuthLogin(provider);
  // }

  // private oAuthLogin(provider) {
  //   return new Promise((resolve, reject) => {
  //     this.afAuth.auth.signInWithPopup(provider)
  //       .then(credentials => {
  //         resolve(credentials);
  //       })
  //       .catch(error => {
  //         reject(this.translateErrorMessage(error.code));
  //       });
  //   });
  // }

  /**
   * signOut
   */
  public signOut() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut()
        .then(() => {
          console.log('signout success!');
          return resolve();
        })
        .catch(error => {
          return reject({
            code: error.code,
            message: this.translateErrorMessage(error.code)
          });
        });
    });
  }

  public translateErrorMessage(errorCode: string) {
    const errorMessage = [];
    if (errorCode === 'auth/weak-password') {
      errorMessage.push('パスワードは６文字以上で入力してください');
    } else if (errorCode === 'auth/email-already-in-use') {
      errorMessage.push('このアカウントはすでに登録されています。');
    } else if (errorCode === 'auth/wrong-password') {
      errorMessage.push('パスワードが違います');
    } else if (errorCode === 'auth/user-not-found') {
      errorMessage.push('このアカウントは登録されていません');
    } else if (errorCode === 'auth/too-many-requests') {
      errorMessage.push('リクエストが多すぎたため中断しました。再度ボタンを押してください。');
    } else if (errorCode === 'auth/network-request-failed') {
      errorMessage.push('インターネットに接続されていないため中断しました。');
    } else {
      errorMessage.push(errorCode);
    }
    return errorMessage;
  }

}
