import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControlName, FormControl, FormArray, Validators } from '@angular/forms';
import { AuthService } from '../common/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { DatabaseService } from '../common/database/database.service';
import { CoreConst } from 'src/app/common/const/const';
declare var require: any;
const platform = require('platform');
import { GoogleAnalyticsEventsService } from 'src/app/common/google/google-analytics-events.service';
import { getYear, getMonth, getDate, addMonths, distanceInWords, differenceInDays, differenceInCalendarDays } from 'date-fns';
import { User } from '../common/interfaces/interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  rootFormGroup: FormGroup;
  errorMessage = [];

  version = CoreConst.VERSION.version;

  showNavigationArrows = false;
  showNavigationIndicators = false;

  redirectURL: string;


  constructor (
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private db: DatabaseService,
    private toastr: ToastrService,
    private gaes: GoogleAnalyticsEventsService,

  ) {
    this.rootFormGroup = this.fb.group({
      email: [
        '',
        Validators.required
      ],
      password: [
        '',
        Validators.required
      ],
    });
  }

  ngOnInit() {
    // 強制的にサインアウト
    this.auth.signOut();
    this.auth.checkBrowser();

    // console.log(this.router.url);

    const params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectURL = params['redirectURL'];
    }

  }


  public onSubmit(account: any) {

    this.gaes.sendEvent('login', 'login', 'login', null);

    this.errorMessage = [];

    this.auth.signInWithEmailAndPassword(account.email, account.password)
      .then((credential: any) => {
        // console.log(credential);
        if (typeof credential !== 'undefined') {
          // console.log(credential);

          // console.log(credential.user);

          // 認証がとれてなければ確認メール送信
          if (credential.user.emailVerified === false) {
            // 2019年１月以降に確認画面を作成して遷移する
            // this.auth.sendEmailVerification();
          }

          // check
          this.db.get('users', credential.user.uid).subscribe((u: any) => {

            const user = { ...CoreConst.INIT_USER, ...u };
            // 中止遷移
            if (user.disabled) {
              // アクセス許可がないので中止、遷移
              this.toastr.error('エラー:このアカウントでは現在アクセスできません。アクセスをご希望の場合は、活性化事務局にお問い合わせください。');
              return;
            } else if (typeof user.displayName === 'undefined' || user.displayName === '') {
              // お名前が設定していないので中止、遷移
              this.toastr.error('エラー:お名前が未登録です。登録してください。');
              return this.router.navigate([`./user/${user.uid}`]);
            } else if (typeof user.group === 'undefined' || user.group.id === 'undefined' || user.group.id === '') {
              // 目標設定していないので中止、遷移
              this.toastr.error('エラー:経営研究会が未登録です。登録してください。');
              return this.router.navigate([`./user/${user.uid}`]);
            } else if (typeof user.group.block === 'undefined' || user.group.block.no === null) {
              // 目標設定していないので中止、遷移
              this.toastr.error('エラー:経営研究会が未登録です。登録してください。');
              return this.router.navigate([`./user/${user.uid}`]);
            } else if (typeof user.role === 'undefined' || user.role.value === 'undefined' || user.role.value === '') {
              // 報告者区分が未設定なので中止、遷移
              this.toastr.error('エラー:報告者区分が未登録です。登録してください。');
              return this.router.navigate([`/user/${user.uid}`]);
            } else {
              // slack通知
              // if (user.role.value !== 'manager') {
              const message = `活性化 v${this.version} ログイン\n${user.group.name}\n${user.role.name}:${user.displayName}\nemailVerified:${credential.user.emailVerified}\n${platform.description}`;
              const payload = { text: message };
              // this.db.sendSlack(payload);
              this.toastr.success('ログインしました');
              // }
              // RedirectURL処理
              if (this.redirectURL) {
                this.router.navigateByUrl(this.redirectURL)
                  .catch(() => this.router.navigate(['./staff']));
              } else {
                this.router.navigate(['./staff']);
              }
              // this.router.navigate([`./staff`]);
            }


          }, error => {
            // authenticated していないのでエラーでるが正常
          });

        }
      }).catch((error: any) => {
        // console.log(error);
        if (error.code === 'auth/wrong-password') {
          this.toastr.error('パスワードが違います');
        } else {
          const message = `活性化 v${this.version} ログインエラー:signinCatch${account.email}\n${error.message}\n${platform.description}`;
          const payload = { text: message };
          // this.db.sendSlack(payload);
          // this.toastr.error('ログインエラー:signincatch');
        }
        this.errorMessage.push(error.message);
      });
  }


}
