import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControlName, FormControl, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase';
// import { NgxSpinnerService } from 'ngx-spinner';
import { DatabaseService } from '../common/database/database.service';
import { CoreConst } from '../common/const/const';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  rootFormGroup: FormGroup;
  groups: any;

  errorMessage = [];

  account: any;

  roles = CoreConst.INIT_ROLES;
  role: any;
  passcode: string;
  checkFlg = false;

  version = CoreConst.VERSION.version;

  showNavigationArrows = false;
  showNavigationIndicators = false;
  images = [1, 2, 3].map((i) => `../../assets/nisouken/image${i}.jpg`);

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    // private spinner: NgxSpinnerService,
    private db: DatabaseService,
  ) {
    this.rootFormGroup = this.fb.group({
      uid: ['',
      ],
      email: [
        '',
        Validators.required
      ],
      password: [
        '',
        Validators.required
      ]
    });
  }

  ngOnInit() {
    // 強制的にサインアウト
    this.auth.signOut();
    this.auth.checkBrowser();
  }

  // public signin(e) {
  //   // 中断
  //   e.preventDefault();

  //   this.errorMessage = [];

  //   this.account = this.rootFormGroup.value;
  //   // console.log(this.account);

  //   this.auth.signInWithEmailAndPassword(this.account.email, this.account.password)
  //     .then(credential => {
  //       if (typeof credential !== 'undefined') {
  //         console.log(credential);
  //         this.toastr.success('ログインしました');

  //         this.db.get('users', credential.user.uid)
  //           .subscribe((res: any) => {
  //             console.log(res);
  //             if (typeof res === 'undefined') {
  //               this.router.navigate([`./user/${credential.user.uid}`]);
  //               return;
  //             }
  //             if (typeof res.displayName === 'undefined' || res.displayName === '') {
  //               this.router.navigate([`./user/${credential.user.uid}`]);
  //               return;
  //             }
  //             if (typeof res.group === 'undefined' || res.group.id === '') {
  //               this.router.navigate([`./user/${credential.user.uid}`]);
  //               return;
  //             }
  //           });
  //       }
  //     }).catch(error => {
  //       console.log(error);
  //       if (error.code === 'auth/wrong-password') {
  //         this.toastr.error('パスワードが違います');
  //       } else {
  //         this.toastr.error(error.message);
  //       }
  //       this.errorMessage.push(error.message);
  //     });
  // }

  public check() {

    // this.spinner.show();
    this.errorMessage = [];

    if (typeof this.role.value === 'undefined' || this.role.value === '' || this.role.value === 'undefined') {
      this.errorMessage.push('報告者区分を選択してください');
      // this.spinner.hide();
      return;
    }

    // console.log(this.role);

    if (typeof this.passcode === 'undefined' || this.passcode === '') {
      this.errorMessage.push('招待コードを入力してください');
      // this.spinner.hide();
      return;
    }

    // console.log(this.passcode, this.role.value);

    this.checkInvitationCode(this.passcode, this.role.value)
      .then(res => {
        // console.log(res);
        if (res) {
          setTimeout(() => {
            this.checkFlg = true;
            // this.spinner.hide();
            this.toastr.success('招待コードは正常に確認できました');
          }, 2000);
        } else {
          this.errorMessage.push('招待コードが違います');
          // this.spinner.hide();
        }
      });

  }

  public onSubmit(account: any) {

    this.account = this.rootFormGroup.value;
    // console.log(this.rootFormGroup.value);

    this.errorMessage = [];

    // if (!this.passcode) {
    //   this.errorMessage.push('招待コードを入力してください');
    //   return;
    // }

    // this.checkInvitationCode(this.passcode, 'staff')
    //   .then(res => {
    //     console.log(res);
    //     if (res) {
    this.auth.createUserWithEmailAndPassword(this.account.email, this.account.password)
      .then((data: any) => {
        // console.log('sucess', data.user);
        // 削除
        delete this.account.password;
        delete this.account.passcode;

        this.account.uid = data.user.uid;

        // 役割権限の格納
        this.account.role = this.role;

        // 新規登録
        this.db.set_id('users', data.user.uid, this.account);

        // 遷移
        this.router.navigate([`./user/${data.user.uid}`]);
        // this.auth.signup_account = {};

      }).catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          // ログイン画面
          this.errorMessage.push(error.message);
          this.router.navigate(['./login']);
        } else {
          // this.errorMessage = error.message;
          this.errorMessage.push(error.message);
        }
        // console.log(error.message);
      });
    //   } else {
    //     this.errorMessage.push('招待コードが違います');
    //   }
    // });
  }

  private checkInvitationCode(passcode: string, type: string) {
    return new Promise((resolve, reject) => {
      this.db.get('setting', '2018')
        .subscribe(res => {
          // console.log(res);
          if (type === 'admin') {
            if (passcode === res.passcode.admin) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'nisouken') {
            if (passcode === res.passcode.nisouken) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'honbu') {
            if (passcode === res.passcode.honbu) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'manager') {
            if (passcode === res.passcode.manager) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'block') {
            if (passcode === res.passcode.block) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'group') {
            if (passcode === res.passcode.group) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'readonly') {
            if (passcode === res.passcode.readonly) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'office') {
            if (passcode === res.passcode.office) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else if (type === 'soumu') {
            if (passcode === res.passcode.soumu) {
              resolve(true);
            } else {
              resolve(false);
            }
          }

        });
    });
  }
}
// https://angularfirebase.com/lessons/google-user-auth-with-firestore-custom-data/
