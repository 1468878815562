import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../common/auth/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormControlName, FormControl, FormArray, Validators } from '@angular/forms';
import { tap, map, take } from 'rxjs/operators';
import { DatabaseService } from '../../common/database/database.service';
import { Observable } from 'rxjs';
import { CoreConst } from '../../common/const/const';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class UserEditComponent implements OnInit {

  currentUser: any;

  rootFormGroup: FormGroup;

  groups: any;
  groups$: Observable<any>;

  passcode: string;
  errorMessage = [];
  uid: string;
  type: string;

  roles = CoreConst.INIT_ROLES;

  constructor (
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private db: DatabaseService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
  ) {
    this.rootFormGroup = this.fb.group({
      uid: [
        '',
      ],
      email: [
        '',
      ],
      group: [
        {},
        Validators.required
      ],
      displayName: [
        '',
        Validators.required
      ],
      role: [
        {},
        Validators.required
      ],
    });
  }

  ngOnInit() {



    // アカウント情報取得
    this.auth.user.subscribe((user: any) => {
      if (user) {
        ////////////////////////////////////////
        // ユーザー
        this.currentUser = user;
      } else {
      }
    });

    this.route.params.subscribe((params: Params) => {

      this.uid = params['uid'];

      // フォームにuidの値を格納
      this.groups$ = this.afs.collection<any>('groups', ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('deleted', '==', false);
        query = query.orderBy('no', 'asc');
        return query;
      }).valueChanges();

      this.groups$.subscribe(res => {
        // console.log(res);
        this.groups = res;

        this.db.get('users', this.uid)
          .subscribe(user => {
            // console.log(user);
            if (user) {
              this.rootFormGroup.patchValue(user);
              // console.log(this.rootFormGroup.value);

              // セレクタ更新
              if (typeof user.role !== 'undefined') {
                const idx1 = this.roles.findIndex(x => x.id === user.role['id']);
                // console.log('idx1', idx1);
                if (idx1 !== -1) {
                  user.role = this.roles[idx1];
                  this.rootFormGroup.patchValue(user);
                }
              }


              // セレクタ更新
              if (typeof user.group !== 'undefined') {
                const idx1 = this.groups.findIndex((x: any) => x.id === user.group['id']);
                // console.log('idx1', idx1);
                if (idx1 !== -1) {
                  user.group = this.groups[idx1];
                  this.rootFormGroup.patchValue(user);
                }
              }

            } else {
              this.rootFormGroup.patchValue({ uid: this.uid });
            }
          });

      });



    });

    // 強制的にサインアウト
    // this.auth.signOut();
    // this.auth.checkBrowser('staff');
  }


  public onSubmit(account: any) {
    // console.log(this.currentUser, account, this.rootFormGroup.value);

    this.db.update('users', this.uid, account);
    this.toastr.success('登録完了しました');

    if (this.currentUser) {
      if (this.currentUser.role.value === 'manager') {
        return;
      } else if (account.role.value === 'admin') {
        return this.router.navigate(['/', 'admin', 'dashboard']);
      } else if (account.role.value === 'manager') {
        return this.router.navigate(['/', 'staff', 'dashboard']);
      } else if (account.role.value === 'staff') {
        return this.router.navigate(['/', 'staff', 'dashboard']);
      } else if (account.role.value === 'honbu') {
        return this.router.navigate(['/', 'staff', 'dashboard']);
      } else {
        return this.router.navigate(['/', 'staff', 'dashboard']);
      }
    } else {
      return this.router.navigate(['/', 'login']);
    }

  }

}
