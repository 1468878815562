import { Injectable } from '@angular/core';
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsEventsService {

  constructor() { }

  public sendEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {

    const fieldsObject = {
      nonInteraction: true
    };

    // console.log(eventCategory, eventAction, eventLabel, eventValue);
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });

  }

}
