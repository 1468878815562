import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JsonpModule, HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { FirestoreSettingsToken } from '@angular/fire/firestore';

import { environment } from '../environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// this modules components
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { UserEditComponent } from './user/edit/edit.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { VerifyComponent } from './verify/verify.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    PageNotFoundComponent,
    BrowserNotSupportedComponent,
    UserEditComponent,
    ForgetPasswordComponent,
    VerifyComponent,
    MaintenanceComponent,
  ],
  imports: [
    JsonpModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AngularFireFunctionsModule,
    ToastrModule.forRoot(
      {
        timeOut: 3000,
      }
    ), // ToastrModule added
    NgxSpinnerModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDropdownModule
  ],
  providers: [
    CookieService,
    { provide: FirestoreSettingsToken, useValue: {} },
    // { provide: FunctionsRegionToken, useValue: 'asia-northeast1' }
  ], // angular/firstoreが更新されるまで、コンソールで表示されるエラーをコメントアウト
  bootstrap: [AppComponent]
})
export class AppModule { }
