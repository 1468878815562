import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControlName, FormControl, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  rootFormGroup: FormGroup;
  account: { email: '' };
  email: string;
  sendFlg = false;
  errorMessage = [];

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.rootFormGroup = this.fb.group({
      email: [
        '',
        Validators.required
      ],
    });
  }

  ngOnInit() {
    // 強制的にサインアウト
    this.auth.signOut();
    this.auth.checkBrowser();
  }

  public onSubmit() {
    this.account = this.rootFormGroup.value;
    this.auth.sendPasswordResetEmail(this.account.email)
      .then(() => {
        this.sendFlg = true;
      })
      .catch(error => {
        this.errorMessage = [];
        this.errorMessage = [...error.errorMessage];
      });
  }

}
